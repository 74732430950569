import requeset from '../Http/request';

const HomeApi = () => {
  return {
    // 核销列表
    hxOrder: (params) => {
      return requeset({
        url: `/admin/hx/orders?phone=${params}`,
        method: 'GET',
      });
    },
    //  核销
    xhOnTap: (params) => {
      return requeset({
        url: `/admin/hx?orderId=${params}`,
        method: 'POST',
      });
    },
    //获取已核销小区列表
    hxAddress: () => {
      return requeset({
        url: '/admin/hx/address',
        method: 'GET',
      });
    },
    //获取已核销记录
    hxLogsList: (params) => {
      return requeset({
        url: '/admin/hx/list',
        method: 'GET',
        params,
      });
    },
  };
};

export default HomeApi;
