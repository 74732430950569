import axios from 'axios';
// 新建一个axios实例
let baseUrl = '';
// 判断环境
if (process.env.NODE_ENV === 'development') {
  // 本地环境
  baseUrl = 'https://api.yanfang.shifen.cn';
} else {
  // 线上环境
  baseUrl = 'https://api.yanfang.shifen.cn';
}
const service = axios.create({
  baseURL: baseUrl, //api地址
  timeout: 50000,
  headers: { 'Content-Type': 'application/json' },
});

// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在发送请求只求需要做些什么操作
    // 需要token权限则添加token
    const token = sessionStorage.getItem('token');
    config.headers.common['token'] = token;
    return config;
  },
  (error) => {
    // 对错误信息做些什么操作
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // 对响应数据做些什么操作
    const res = response.data;
    if (res.code && res.code !== '10000') {
      return response;
    } else {
      return response;
    }
  },
  (error) => {
    // 对响应错误做些什么操作
    return Promise.reject(error);
  }
);

export default service;
