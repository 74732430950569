import React, { PureComponent, Suspense, lazy } from 'react';
import { Route, BrowserRouter, Routes, Redirect } from 'react-router-dom';
import { getListA } from '../store/modules/login';
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { actions } from '../store/modules/login';
import HomeClass from '../assets/css/home.module.css';
import classnames from 'classnames/bind';
import Tab from '../components/tabar';
import Home from './home';
// import Login from './login';
const Login = lazy(() => import('./login'));
const Yanfan = lazy(() => import('./yanfan'));
const YanfanTwo = lazy(() => import('./yanfanTwo'));
const HomeActivity = lazy(() => import('./home_activity'));
const HomeLogs = lazy(() => import('./home_logs'));
const cls = classnames.bind(HomeClass);

class Index extends PureComponent {
  state = {
    user: {
      name: 'lisa',
      password: 'admin',
    },
  };
  render() {
    return (
      <div className={cls('Layout')}>
        <BrowserRouter>
          <div>
            <Suspense>
              <Routes>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/" element={<Home />} />
                <Route path="/yanfan" element={<Yanfan />} />
                <Route path="/yanfansf" element={<YanfanTwo />} />
                <Route path="/homeActivity" element={<HomeActivity />} />
                <Route path="/homelogs" element={<HomeLogs />} />
                {/* Redirect 重定向 */}
                {/* <Redirect to="/login"></Redirect> */}
              </Routes>
            </Suspense>
          </div>
          <Tab />
        </BrowserRouter>
      </div>
    );
  }
}

export default Index;
