import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import home from './modules/home';
import login from './modules/login';

let reducer = combineReducers({
  home,
  login,
});
let store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
export default store;
