import Api from '../../api/index';
import { Toast } from 'antd-mobile';
import { InformationCircleOutline } from 'antd-mobile-icons';
import { useNavigate } from 'react-router-dom';
const initState = {
  user: {
    name: '张三',
    password: '123456456',
  },
};
const TYPES = {
  USER_NAME: 'USER_NAME',
};
const reduce = (state = initState, action) => {
  // console.log(action);
  switch (action.type) {
    case TYPES.USER_NAME:
      return {
        ...state,
        user: action.user,
      };
    default:
      return state;
  }
};

export const actions = {
  changen: (user) => ({ type: TYPES.USER_NAME, user }),
  loginA: (n) => (dispatch) => {
    dispatch(actions.changen(n));
  },
  login: (n) => (dispatch) => {
    if (n.userName != '' && n.password != '') {
      Toast.show({
        duration: 0,
        icon: 'loading',
        content: '登录中...',
      });
      Api()
        .login(n)
        .then((res) => {
          if (res?.data?.code === 10000) {
            Toast.show({
              icon: 'success',
              content: '登录成功',
            });
            Toast.clear();
            console.log(res?.data?.data?.token);
            sessionStorage.setItem('token', res?.data?.data?.token);
            window.location.reload();
          } else {
            Toast.show({
              content: res.data.data,
              icon: <InformationCircleOutline />,
            });
          }
        });
      // const token = sessionStorage.getItem('token');
      // console.log(token);
      dispatch(actions.changen(n));
    } else {
      Toast.show({
        content: '用户名或密码不能为空',
        // icon: <InformationCircleOutline />,
      });
      return false;
    }
  },
};
export const getListA = (state) => state.login.user;
export default reduce;
