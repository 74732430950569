import React, { PureComponent } from 'react';
import { Button, Toast } from 'antd-mobile';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getListA } from '../store/modules/login';
import { actions } from '../store/modules/login';
import logincls from '../assets/css/login.module.css';
import classNames from 'classnames/bind';
import md5 from 'md5';
const cls = classNames.bind(logincls);
class Login extends PureComponent {
  state = {
    userName: '',
    password: '',
  };
  userNameOntap = (v) => {
    this.setState({
      userName: v.target.value,
    });
  };
  userPasswordOntap = (v) => {
    this.setState({
      password: md5(v.target.value),
    });
  };
  //   OntapLogin = async () => {
  //     if (this.state.userName != '' && this.state.userName) {
  //       const { login } = this.props.h;
  //       //  console.log(login);
  //       await login(this.state);
  //     } else {
  //       Toast.show({
  //         content: '用户名或密码不能为空',
  //       });
  //     }
  //   };
  render() {
    let {
      user,
      h: { login },
    } = this.props;

    return (
      <div>
        <div className={cls('loginTitle')}>登录</div>
        <div className={cls('login')}>
          <div className={cls('Form')}>
            <span>
              <input
                onChange={(v) => this.userNameOntap(v)}
                placeholder="请输入用户名"
                type="text"
                className={cls('input__field', 'input__field--minoru')}
              />
            </span>
            <span style={{ marginTop: '20px', display: 'block' }}>
              <input
                onChange={(v) => this.userPasswordOntap(v)}
                placeholder="请输入用户密码"
                type="text"
                className={cls('input__field', 'input__field--minoru', 'password')}
              />
            </span>
          </div>
          <div style={{ padding: '20px', width: '100%' }}>
            <Button block color="primary" size="large" onClick={() => login(this.state)}>
              提交
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

// 传入props中
const mapStateToProps = (state) => {
  return {
    user: getListA(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    h: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
