import requeset from '../Http/request';

const Api = () => {
  return {
    // 登录api
    login: (params) => {
      return requeset({
        url: `/admin/hx/login`,
        method: 'POST',
        params,
      });
    },
  };
};

export default Api;
