import HomeApi from '../../api/home';
import { Toast, Modal } from 'antd-mobile';
import { PhoneFill } from 'antd-mobile-icons';
const initState = {
  phone: '',
  order: [],
  hxaddresslist: [],
  logsList: {},
};
const TYPES = {
  orderList: 'OrderList',
  PHONE: 'PHONE',
  HXADDRESSLIST: 'HXADDRESSLIST',
  HXADDRESSLOGS: 'HXADDRESSLOGS',
};
const reduce = (state = initState, action) => {
  // console.log(action);
  switch (action.type) {
    case TYPES.orderList:
      return {
        ...state,
        order: action.order,
      };
    case TYPES.PHONE:
      return {
        ...state,
        phone: action.phone,
      };
    case TYPES.HXADDRESSLIST:
      return {
        ...state,
        hxaddresslist: action.arr,
      };
    case TYPES.HXADDRESSLOGS:
      return {
        ...state,
        logsList: action.arr,
      };
    default:
      return state;
  }
};

export const actions = {
  chageAddressList: (arr) => ({ type: TYPES.HXADDRESSLIST, arr }),
  changenphone: (phone) => ({ type: TYPES.PHONE, phone }),
  changen: (order) => ({ type: TYPES.orderList, order }),
  // 获取列表
  OrderOntap: (n) => (dispatch) => {
    if (n === '') {
      Toast.show({
        content: '手机号码不能为空',
        icon: <PhoneFill />,
      });
      return false;
    }
    Toast.show({
      duration: 0,
      icon: 'loading',
      content: '加载中…',
    });
    HomeApi()
      .hxOrder(n)
      .then((res) => {
        if (res?.data?.code === 10000) {
          Toast.clear();
          dispatch(actions.changen(res.data.data));
          dispatch(actions.changenphone(n));
        }
      });
  },
  // 核销操作
  OrderXhOnTap: (n) => (dispatch, getState) => {
    const phone = phoneOrder(getState());
    Modal.confirm({
      content: '是否提交申请',
      onConfirm: async () => {
        await HomeApi()
          .xhOnTap(n)
          .then((res) => {
            if (res?.data?.code === 10000) {
              Toast.show({
                icon: 'success',
                content: '提交成功',
              });
              dispatch(actions.OrderOntap(phone));
            } else {
              Toast.show({
                icon: 'success',
                content: res.data.data,
              });
            }
          });
        // Toast.show({
        //   icon: 'success',
        //   content: '提交成功',
        // });
      },
    });
  },
  //   已核销小区列表
  hxAddressOnTap: () => (dispatch) => {
    HomeApi()
      .hxAddress()
      .then((res) => {
        if (res?.data?.code === 10000) {
          const arr = [];
          if (res?.data?.data?.length > 0) {
            const list = res?.data?.data;
            list.map((item) => {
              let obj = new Object();
              obj.label = item;
              obj.value = item;
              arr.push(obj);
            });
            dispatch(actions.chageAddressList(arr));
          } else {
            return false;
          }
        }
      });
  },
  chageLogsList: (arr) => ({ type: TYPES.HXADDRESSLOGS, arr }),
  //  已经核销的记录
  hxLogsListOnTap: (params) => (dispatch) => {
    HomeApi()
      .hxLogsList(params)
      .then((res) => {
        if (res?.data.code === 10000) {
          const arr = res?.data;
          if (res?.data?.data.list?.length > 0) {
            console.log(arr);
            dispatch(actions.chageLogsList(arr.data));
          } else {
            return false;
          }
        }
      });
  },
};
export const OrderListArr = (state) => state.home.order;
export const phoneOrder = (state) => state.home.phone;
export const hxAddressArr = (state) => state.home.hxaddresslist;
export const hxLogsArr = (state) => state.home.logsList;
export default reduce;
