import tab from '../assets/css/tab.module.css';
import classname from 'classnames/bind';
import { Link } from 'react-router-dom';
import imgxjyf from '../assets/image/xjyf.jpg';
import imgxjyfxf from '../assets/image/xjyf_xd.jpg';
import me from '../assets/image/avater.png';
const cls = classname.bind(tab);
const Tab = () => {
  return (
    <div className={cls('tab')}>
      <div>
        <Link to="/yanfan">
          <img src={imgxjyf}></img>
          <span>祥居验房</span>
        </Link>
      </div>
      <div>
        <Link to="/">
          <img src={me} className={cls('meImg')}></img>
          <span>个人中心</span>
        </Link>
      </div>
      <div>
        <Link to="/yanfansf">
          <img src={imgxjyfxf}></img>
          <span>师傅端</span>
        </Link>
      </div>
    </div>
  );
};
export default Tab;
