import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import homeCss from '../assets/css/home.module.css';
import clasName from 'classnames/bind';
const cls = clasName.bind(homeCss);
class Home extends PureComponent {
  state = {
    obj: {
      phone: 'shifen',
      password: '123456',
    },
  };
  LoginOntap = () => {
    console.log(process.env.NODE_ENV);
  };
  render() {
    return (
      <div>
        <div className={cls('admin')}>核销管理</div>
        <div className={cls('cententer')}>
          <Link to="/homeActivity" className={cls('activity')}>
            活动核销
          </Link>
          <Link to="/homelogs" className={cls('activityB')}>
            核销记录
          </Link>
          {/* <div className={cls('activity')}>核销记录</div> */}
        </div>
      </div>
    );
  }
}

export default Home;
