import React, { useEffect } from 'react';
import Index from './views/index';
import 'antd/dist/antd.min.css';
import './app.css';
import Login from './views/login';
const App = () => {
  const token = sessionStorage.getItem('token');
  console.log(token);
  useEffect(() => {});
  return <div>{token !== null ? <Index /> : <Login />}</div>;
};

export default App;
